<template>
  <div>
    <div class="footer">
      <div class="box">
        <div class="list1">
          <img src="../assets/img/Rectangle.svg" alt="" class="logo" />
          <div class="txt1">MIB for SACCOs</div>
          <div class="txt2">Shared Vision, Amplified Ambition</div>
          <div class="txt3">ABOUT US</div>
          <div class="txt4">
            We are a technology company with a robust team for building and
            supporting SACCO management systems.
          </div>
          <div class="link_img">
            <img src="../assets/img/facebook.svg" alt="" />
            <img src="../assets/img/twier.svg" alt="" />
            <img src="../assets/img/in.svg" alt="" />
          </div>
        </div>
        <div class="list2">
          <div class="title">ABOUT US</div>
          <div class="txt" @click="GoHome">HOME</div>
          <div class="txt" @click="GoAboutUs">ABOUT US</div>
          <!-- <div class="txt">TARGET</div>
        <div class="txt">SOLUTIONS</div> -->
        </div>
        <!-- <div class="list2">
        <div class="title">USEFUL LINKS</div>
        <div class="txt">PRIVACY POLICY</div>
        <div class="txt">TERMS OF USE</div>
        <div class="txt">TERMS & CONDITIONS</div>
        <div class="txt">FAQS</div>
      </div> -->
        <div class="list2">
          <div class="title">CONTACT US</div>
          <a href="tel:+254 706 169 317" class="tel">+254 706 169 317</a>
          <a href="mailto:sacco@mibafrica.com" class="email"
            >sacco@mibafrica.com</a
          >
          <div class="title2">Subscribe for more info</div>
          <input type="text" class="input" v-model="info" />
          <div class="btn" @click="Send">Subscribe</div>
        </div>
      </div>
      <div class="line">
        © MIB African Solutions Limited | All rights reserved.
      </div>
    </div>
    <div class="h5">
      <div class="footer_header">
        <div class="logo_box">
          <img src="../assets/img/Rectangle.svg" alt="" class="logo" />
          <div class="link_img">
            <img src="../assets/img/facebook.svg" alt="" />
            <img src="../assets/img/twier.svg" alt="" />
            <img src="../assets/img/in.svg" alt="" />
          </div>
        </div>
        <div class="left_box">
          <div class="txt1">MIB for SACCOs</div>
          <div class="txt2">Shared Vision, Amplified Ambition</div>
          <div class="txt3">ABOUT US</div>
          <div class="txt4">
            We are a technology company with a robust team for building and
            supporting SACCO management systems.
          </div>
        </div>
      </div>
      <div class="list_box">
        <div class="list1">
          <div class="title">ABOUT US</div>
          <div class="txt" @click="GoHome">HOME</div>
          <div class="txt" @click="GoAboutUs">ABOUT US</div>
        </div>
        <div class="list2">
          <div class="title">CONTACT US</div>
          <a href="tel:+254 706 169 317" class="tel">+254 706 169 317</a>
          <a href="mailto:sacco@mibafrica.com" class="email"
            >sacco@mibafrica.com</a
          >
        </div>
      </div>
      <div class="title2">Subscribe for more info</div>
      <input type="text" class="input" v-model="info" />
      <div class="btn" @click="Send">Subscribe</div>
      <div class="line">
        © MIB African Solutions Limited | All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/http/axios";
export default {
  data() {
    return {
      info: "",
    };
  },
  methods: {
    Send() {
      axios
        .post("/application/contact", {
          message: this.info,
          sourceFrom: "sacc",
        })
        .then((res) => {
          console.log(res);
          this.$ftoast({
            text: "Submit successfully",
          });
        });
    },
    GoHome() {
      this.$router.push("/home");
    },
    GoAboutUs() {
      this.$router.push("/aboutus");
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .footer {
    color: #ffffff;
    background: #622b83;
    min-width: 1200px;
    .box {
      display: flex;
      justify-content: space-around;
      .list1 {
        padding-top: 82px;
        width: 380px;
        .logo {
          width: 135px;
          height: 135px;
          display: block;
          margin-bottom: 32px;
        }
        .txt1 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 37px;
        }
        .txt2 {
          font-style: italic;

          font-size: 18px;
          line-height: 37px;
        }
        .txt3 {
          margin: 25px 0 8px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 37px;
        }
        .txt4 {
          font-style: normal;
          font-size: 18px;
          line-height: 30px;
        }
        .link_img {
          margin: 50px 0;
          img {
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
      .list2 {
        padding-top: 129px;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 37px;
          margin-bottom: 22px;
        }
        .txt {
          margin-bottom: 14px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 37px;
          cursor: pointer;
        }
        .tel {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          height: 38px;
          line-height: 38px;
          margin-bottom: 12px;
          padding-left: 36px;
          color: #ffffff;
          text-decoration: none;
          display: block;
          background-image: url("../assets/img/mobile.svg");
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: left 9px;
        }
        .email {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          height: 38px;
          line-height: 38px;
          margin-bottom: 56px;
          padding-left: 36px;
          color: #ffffff;
          text-decoration: none;
          display: block;
          background-image: url("../assets/img/email.svg");
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: left 9px;
        }
        .title2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 36px;
        }
        .input {
          background-color: #ffffff;
          width: 380px;
          height: 62px;
          line-height: 62px;
          display: block;
          border-radius: 10px;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          outline: none;
          margin: 37px 0;
        }
        .btn {
          width: 380px;
          height: 62px;
          line-height: 62px;
          background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
          border-radius: 10px;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .line {
      width: 90%;
      margin: 0 auto;
      padding: 15px 0 34px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 37px;
      border-top: 3px solid #cc3e91;
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    display: none;
  }
  .h5 {
    width: 100%;
    background: #622b83;
    padding: 40px 3% 12px;
    box-sizing: border-box;
    color: #ffffff;
    .footer_header {
      .logo_box {
        width: 120px;
        display: inline-block;
        vertical-align: top;
        .logo {
          width: 68px;
          height: 68px;
          margin-bottom: 20px;
          display: block;
        }
        .link_img {
          img {
            margin-right: 10px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            height: 20px;
          }
        }
      }
      .left_box {
        width: calc(100% - 120px);
        display: inline-block;
        vertical-align: top;
        color: #ffffff;
        .txt1 {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        }
        .txt2 {
          font-style: italic;

          font-size: 12px;
          line-height: 18px;
        }
        .txt3 {
          margin: 12px 0 4px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        }
        .txt4 {
          font-style: normal;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .list_box {
      padding-top: 30px;
      color: #ffffff;
      .list1 {
        width: 120px;
        display: inline-block;
        vertical-align: top;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .txt {
          margin-bottom: 7px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          cursor: pointer;
        }
      }
      .list2 {
        width: calc(100% - 120px);
        display: inline-block;
        vertical-align: top;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .tel {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 20px;
          line-height: 20px;
          margin-bottom: 6px;
          padding-left: 18px;
          color: #ffffff;
          text-decoration: none;
          display: block;
          background-image: url("../assets/img/mobile.svg");
          background-repeat: no-repeat;
          background-size: 12px 12px;
          background-position: left 5px;
        }
        .email {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 20px;
          line-height: 20px;
          padding-left: 18px;
          color: #ffffff;
          text-decoration: none;
          display: block;
          background-image: url("../assets/img/email.svg");
          background-repeat: no-repeat;
          background-size: 12px 12px;
          background-position: left 5px;
        }
      }
    }
    .title2 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      padding-top: 20px;
    }
    .input {
      background-color: #ffffff;
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: block;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      outline: none;
      margin: 19px 0;
      box-sizing: border-box;
    }
    .btn {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background: linear-gradient(180deg, #fa945a 0%, #cc3e91 100%);
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 30px;
    }
    .line {
      width: 100%;
      margin: 0 auto;
      padding: 8px 0 16px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      border-top: 1px solid #cc3e91;
    }
  }
}
</style>
