import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
const server = axios.create({
  timeout: 60000,
  headers: {
    "Content-Type": "application/json; charset=utf-8;",
    'Accept': "application/json",
  }
})


server.interceptors.request.use(function (config) {
  config.headers['token'] = sessionStorage.getItem('token') || '';
  // startLoading()
  return config
}, function (error) {
  return Promise.reject(error)
});

/****** respone拦截器==>对响应做处理 ******/
server.interceptors.response.use(function (response) {
  //成功请求到数据
  // endLoading();
  return response.data

}, function (error) {

  return Promise.reject(error)
});

export default {

  /**
   * post方法，对应post请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   */
  post(url, params) {
    return new Promise((resolve, reject) => {
      server.post(url, params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err)
        })
    });
  },

}