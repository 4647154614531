<template>
  <div id="app">
    <Headers></Headers>
    <transition>
      <router-view></router-view>
    </transition>
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "@/components/Header";
import Footers from "@/components/Footer";
export default {
  name: "App",
  components: {
    Headers,
    Footers,
  },
};
</script>
<style>
@import url("./assets/css/reset.css");
#app {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
</style>
