import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(["@/view/Home.vue"], resolve),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/contact',
    name: 'Contact us',
    component: resolve => require(["@/view/Contact.vue"], resolve),
    meta: {
      title: 'Contact us'
    }
  },
  {
    path: '/modules',
    name: 'Our Modules',
    component: resolve => require(["@/view/Modules.vue"], resolve),
    meta: {
      title: 'Our Modules'
    }
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: resolve => require(["@/view/AboutUs.vue"], resolve),
    meta: {
      title: 'About Us'
    }
  },
]

const router = new Router({
  mode: 'history',
  fallback: false,
  // base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {  //如果未匹配到路由
    next('/')
  } else {
    store.commit('SetActivePath', to.name)
    next()
  }

})
export default router;