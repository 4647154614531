import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    activePage: '',
    activePath: '',
  },
  mutations: {
    SetActivePage(state, page) {
      state.activePage = page;
    },
    SetActivePath(state, activePath) {
      state.activePath = activePath;

    },
  }, getters: {
    GetPage(state) {
      return state.activePage;
    },
    GetActivePath(state) {
      return state.activePath;
    }
  }
})
export default store;