<template>
  <div>
    <div class="header clearfix">
      <img
        src="../assets/img/Rectangle.svg"
        alt=""
        class="logo"
        @click="GoUrl('/')"
      />
      <div class="nav_box">
        <div
          :class="item.value == $store.getters.GetPage ? 'nav active' : 'nav'"
          v-for="item in list"
          :key="item.value"
          @click="GoUrl(item.url)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="h5">
      <div class="h5_box" :class="active ? 'active' : ''">
        <img
          src="@/assets/img/Rectangle.svg"
          @click="GoUrl('/')"
          class="logo"
        />
        <div class="title_b" v-if="active">{{ title }}</div>
        <img
          src="@/assets/img/tabs.svg"
          alt=""
          class="nav_tabs"
          @click="showDrag = true"
        />
        <div class="drag" v-if="showDrag">
          <img src="@/assets/img/h5/close.svg" @click="Close" class="close" />
          <div class="list_box">
            <div
              class="list"
              v-for="item in list"
              :key="item.value"
              @click="GoUrl(item.url)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          label: "HOME",
          value: "home",
          url: "/",
        },
        {
          label: "ABOUT US",
          value: "aboutus",
          url: "/aboutus",
        },
        // {
        //   label: "TARGET",
        //   value: "target",
        //   url: "/",
        // },
        {
          label: "MODULES",
          value: "modules",
          url: "/modules",
        },
        {
          label: "CONTACT US",
          value: "contactus",
          url: "/contact",
        },
      ],
      showDrag: false,
      active: false,
      title: this.$store.getters.GetActivePath,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    GoUrl(url) {
      this.showDrag = false;
      this.$router.push(url);
    },
    Close() {
      this.showDrag = false;
    },
    handleScroll() {
      this.title = this.$store.getters.GetActivePath;
      let scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scroll > 80) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .header {
    width: 100%;
    min-width: 1200px;
    background-color: transparent;
    color: #fff;
    position: relative;
    z-index: 20;
    padding: 70px 12% 0;
    box-sizing: border-box;
    .logo {
      float: left;
      width: 90px;
      height: 90px;
    }
    .nav_box {
      float: right;
      padding: 30px 0;
      .nav {
        display: inline-block;
        vertical-align: top;
        margin-left: 32px;
        padding: 0 12px;
        height: 30px;
        line-height: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;
        &.active {
          background-color: #fff;
          color: rgba(98, 43, 131, 1);
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    display: none;
  }
  .h5 {
    width: 86px;
    width: 100%;
    position: relative;
    .h5_box {
      width: 100%;
      padding: 20px 24px;
      box-sizing: border-box;
      z-index: 200;
      position: fixed;
      left: 0;
      top: 0;
      overflow: hidden;
      background: transparent;
      &.active {
        background-color: #622b83;
      }
      .logo {
        float: left;
        width: 45px;
        height: 45px;
      }
      .title_b {
        float: left;
        width: calc(100% - 63px);
        height: 45px;
        line-height: 45px;
        color: #fff;
        font-size: 18px;
        text-align: center;
      }
      .nav_tabs {
        float: right;
        width: 18px;
        height: 12px;
        padding: 17px 0;
      }
      .drag {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        right: 0;
        background: #622b83;
        .close {
          float: right;
          padding-top: 20px;
          padding-right: 8%;
          width: 25px;
          height: 25px;
        }
        .list_box {
          padding-top: 35px;
          .list {
            padding: 20px 0;
            border-bottom: 1px solid #ffffff;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
